export default {
  appName: "HI-EO",
  defaultPath: "/auth/signin",
  basename: "", // only at build time to set, like ///able-pro/react/default
  layout: "vertical", // vertical, horizontal
  subLayout: "", // horizontal-2
  collapseMenu: false, // mini-menu
  layoutType: "menu-light", // menu-dark, menu-light, dark
  headerBackColor: "header-blue", // background-blue, background-red, background-purple, background-info, background-green, background-dark, background-grd-blue, background-grd-red, background-grd-purple, background-grd-info, background-grd-green, background-grd-dark, background-img-1, background-img-2, background-img-3, background-img-4, background-img-5, background-img-6
  rtlLayout: false,
  navFixedLayout: true,
  headerFixedLayout: false,
  boxLayout: false,
  dataTableCustomStyle: {
    rows: {
      style: {
        // minHeight: '72px', // override the row height
      },
    },
    headCells: {
      style: {
        fontSize: "13px",
        fontWeight: "600",
        color: "#fff",
        background: "#4680ff",
        // textTransform: "uppercase"
      },
    },
    cells: {
      style: {
        // paddingRight: '8px',
      },
    },
  },
  renderSetTimeout: 1000,
  superAdmins:["pegotec_admin"],
  // apiURL: 'http://127.0.0.1:8000',
  // apiURL: "https://api.hi-eo.pegotec.net",
  apiURL: "https://api.hi-eo.pegotec.dev",
};
